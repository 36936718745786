<template>
  <div class="tournament-page__footer-section">
    <div class="rules-section">
      <div class="tournament-page__section-title">Rules</div>
      <div class="container" v-html="tournament.rules"></div>
    </div>

    <div class="contacts-section">

      <div class="tournament-page__section-title">Contacts</div>
      <div class="content">
        <div v-html="tournament.contact_information"></div>
        <div class="social-icons">
          <!-- <a href="https://www.facebook.com/3-Oaks-Gaming-108423585065971" target="_blank" rel="nofollow noopener noreferrer">
            <img src="@/assets/img/tournaments/social-facebook-icon.svg"/>
          </a> -->
<!--          <a href="#" target="_blank" rel="nofollow noopener noreferrer">-->
<!--            <img src="@/assets/img/tournaments/social-twitter-icon.svg"/>-->
<!--          </a>-->
<!--          <a href="#" target="_blank" rel="nofollow noopener noreferrer">-->
<!--            <img src="@/assets/img/tournaments/social-instagram-icon.svg"/>-->
<!--          </a>-->
          <a href="https://www.linkedin.com/company/kendooplay/?viewAsMember=true" target="_blank" rel="nofollow noopener noreferrer">
            <img src="@/assets/img/tournaments/social-linkedin-icon.svg"/>
          </a>
        </div>
      </div>
    </div>
  </div>
  <div class="footer__bottom">
    <div>
        <p><a href="/static/media/privacy_policy.pdf" target="_blank">Privacy Policy</a></p>
    </div>
    <div>
        <p>© {{ year }} KENDOO. All Rights Reserved</p>
    </div>
   </div>
</template>

<script>
export default {
    name: "TournamentFooterSection",
    props: {
        tournament: Object,
    },

    data() {
        return {
            year: new Date().getFullYear()
        }
    },
}
</script>

<style scoped>

</style>