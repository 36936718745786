import {createRouter, createWebHistory} from 'vue-router';

import Auth from '@/lib/auth';
import EventBus from '@/lib/event_bus';

import Home from '@/pages/home/_index';
import About from '@/pages/about/_index';
import Games from '@/pages/games/_index';
import Game from '@/pages/game/_index';
import GameForAuthUser from '@/pages/game_auth/_index';
import Contacts from '@/pages/contacts/_index';
import Error403 from "@/pages/errors/error403";
import Error404 from '@/pages/errors/error404';
import Error500 from "@/pages/errors/error500";
import ErrorUserLoad from "@/pages/errors/errorUserLoad";
import ResetPassword from '@/pages/reset_password';
import Documents from '@/pages/documents/_index';
import Summary from '@/pages/summary/_index';
import Login from '@/pages/login/_index';
import News from '@/pages/news/_index';
import NewsArticle from '@/pages/news_article/_index';
import ClientArea from '@/pages/client_area/_index';
import Campaigns from '@/pages/campaigns/_index';
import Campaign from '@/pages/campaign/_index';
import ProductRoadmapAuth from '@/pages/product_roadmap_auth/_index';
import GamesAuth from '@/pages/games_auth/_index';
import CustomPageAuth from '@/pages/custom_page_auth/_index';
import Tournament from "@/pages/tournament/_index";
import TournamentDemoStand from "@/pages/tournament/demo_stand/_index";
import {settings} from "@/lib/settings";
import FilesDownload from '@/pages/files_download/_index';
import Partners from '@/pages/partners/_index';
import PrivacyNotice from '@/pages/privacy_notice/_index';
import GameVerification from '@/pages/game_verification/_index';


const router = createRouter({
    history: createWebHistory('/'),
    routes: [
        {
            path: "/:catchAll(.*)",
            component: Error404,
        },
        {
            path: "/page-forbidden",
            component: Error403,
            name: 'page-forbidden',
            meta: {
                replaceView: true,
            }
        },
        {
            path: "/page-not-found",
            component: Error404,
            name: 'page-not-found',
            meta: {
                replaceView: true,
            }
        },
        {
            path: "/server-error",
            component: Error500,
            name: 'server-error',
            meta: {
                replaceView: true,
            }
        },
        {
            path: '/news',
            component: News,
            name: 'news',
        },
        {
            path: '/news/:article',
            component: NewsArticle,
            name: 'news-article'
        },
        {
            path: '/client-area',
            name: 'client-area',
            components: {
                default: Login,
                auth: ClientArea,
            },
            redirect: (to) => {
                return {name: 'client-area-product-roadmap'};
            },
            meta: {
                loginRequired: true,
            },
            children: [
                {
                    path: 'product-roadmap',
                    name: 'client-area-product-roadmap',
                    component: ProductRoadmapAuth,
                },
                {
                    path: 'campaigns',
                    name: 'client-area-campaigns',
                    component: Campaigns,
                },
                {
                    path: 'campaigns/:name',
                    name: 'client-area-campaigns-campaign',
                    // props: (route) => ({lang: route.query.lang}),
                    component: Campaign,
                },
                {
                    path: 'games',
                    name: 'client-area-games',
                    component: GamesAuth,
                },
                {
                    path: 'game/:name',
                    name: 'client-area-game',
                    props: (route) => ({lang: route.query.lang}),
                    component: GameForAuthUser,
                },
                {
                    path: 'documents',
                    component: Documents,
                },
                {
                    path: 'summary',
                    component: Summary,
                    meta: {
                        fullScreen: true
                    }
                },
                {
                    path: 'tournament',
                    component: Tournament,
                    meta: {
                        fullScreen: true
                    }
                },
                {
                    path: 'custom-pages/:slug',
                    component: CustomPageAuth,
                },
            ]
        },
        //Not need for first version
        // {
        //     path: '/roadmap',
        //     // component: Games,
        // }, {
        //     path: '/partners',
        //     // component: Games,
        // }, {
        //     path: '/news',
        //     // component: Games,
        // }, {
        //     path: '/career',
        //     // component: Games,
        // },
        // {
        //     path: '/contacts',
        //     component: Contacts,
        // },
        // {
        //     path: '/tournaments/:tournamentNameOrDomain/demo-stand',
        //     name: 'tournament-demo-stand',
        //     component: TournamentDemoStand,
        //     meta: {
        //         fullScreen: true
        //     },
        //     props: {
        //         isStandMode: true,
        //     }
        // },

        // {
        //     path: '/tournaments/:tournamentNameOrDomain',
        //     component: TournamentDemoStand,
        //     meta: {
        //         fullScreen: true
        //     },
        // },

        // {
        //     path: '/tournaments/:tournamentNameOrDomain/old',
        //     component: Tournament,
        //     meta: {
        //         fullScreen: true
        //     },
        // },

        {
            path: '/reset_password_confirm',
            props: (route) => ({token: route.query.token}),
            component: (e => {
                let urlParams = new URLSearchParams(window.location.search);
                let token = urlParams.get('token');
                if (token) {
                    return ResetPassword;
                } else {
                    return Error404;
                }
            })()
        }
    ]
});

router.beforeEach((to, from, next) => {

    const completeProcessing = (userData) => {
        if (to.path === '/') {
            try {
                const serviceName = (settings.SERVICE_NAME).split(':')[0];
                if (serviceName) {
                    const domain = window.location.host.split(':')[0]
                        .replace(`${serviceName}`, '')
                        .slice(0, -1);

                    if (domain) {
                        to.matched[0].components.default = TournamentDemoStand
                        to.params = {'tournamentNameOrDomain': domain};
                        to.query.domain = '1';
                        to.meta = {
                            fullScreen: true,
                        };
                    }
                }
            } catch (err) {

            }
        }

        userData = userData || {};

        const toMeta = to.meta || {};
        if (userData.is_authorized && toMeta.auth) {
            const authRedirect = toMeta.auth.redirect;
            if (authRedirect) {
                next(authRedirect);
                return;
            }
        }

        if (toMeta.replaceView === true && from.matched.length > 0) {
            from.matched[0].components.default = to.matched[0].components.default;
            next(from);
        } else {
            next();
        }
    };

    const authUser = () => {

        Auth.getUser({
            success: (res) => {
                const userData = res.data;
                EventBus.emit(EventBus.EVENTS.USER_LOADED, userData);
                if (userData && userData.is_authorized) {
                    let components = to.matched[0].components;
                    if (components.auth) {
                        components.default = components.auth;
                    }
                }
                completeProcessing(userData);
            },
            error: (err) => {
                EventBus.emit(
                    EventBus.EVENTS.USER_LOADING_ERROR,
                    {error: 'Unable to load user'});
                to.matched[0].components.default = ErrorUserLoad;
                completeProcessing();

                console.log('------------ TODO USER LOAD HANDLE ERROR!', err);
            }
        });
    };

    authUser();

});

export default router;
