<template>
  <section class="offset-section _container">
    <!-- PAGE SLIDER -->
    <div class="page-slider timeline" v-if="items.length > 0">
      <div class="page-slider__view">
        <div
            id="page-slider-1"
            class="page-slider__slider _swiper"
            ref="swiper">
          <div class="swiper-wrapper">
            <div
                v-for="item in items"
                class="page-slider__slide swiper-slide">
              <ProductRoadmapItemCard
                  :title="item['title']"
                  :description="item['description']"
                  :start-date="item['start_date']"
                  :end-date="item['end_date']"
                  :image-url="item['image']"
                  :show-timeline-date="item['show_timeline_date']"
                  :link="item['link']"
                  :locale="uk"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="page-slider__controls">
        <div class="page-slider__nav">
          <div class="page-slider__progressbar">
            <div
                class="page-slider-1__progressbar"
                ref="progressbar"
            ></div>
          </div>
          <div class="page-slider__buttons">
            <div
                class="page-slider__prev-button page-slider-1__prev _icon-arrow-1"
                ref="prevSlideButton"></div>
            <div
                class="page-slider__next-button page-slider-1__next _icon-arrow-1"
                ref="nextSlideButton"
            ></div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="items.length === 0">
      Currently, the event list is not available. <br />
      But it will be published soon.
    </div>

  </section>
</template>

<script>

import {appFetchJSON} from "@/lib/request_utils";
import {getDate} from "@/lib/common_utils";
import Swiper from "@/assets/js/Swiper";
import ProductRoadmapItemCard from "./product_roadmap_item_card";

export default {
    name: "ProductRoadmapComingSoonBlock",

    components: {
        ProductRoadmapItemCard
    },

    created() {
        this.swiperActiveSlideIndex = -1;
    },

    data() {
        return {
            items: [],
        }
    },

    mounted() {
        this.fetchData().then(() => {
            this.initSlider();
            if (this.swiperActiveSlideIndex >= 0) {
                this.$nextTick(() => {
                    this.swiper.slideTo(this.swiperActiveSlideIndex);
                })
            }
        });
    },

    methods: {
        fetchData() {
            this.swiperActiveSlideIndex = -1;
            return appFetchJSON('/product-roadmap')
                .then((res) => {
                    const items = res.data?.items || [];
                    if (!items.length) {
                        this.items = [];
                        return;
                    }

                    items.sort(this.itemsCompareFn);

                    const currentDate = getDate(new Date());

                    items.forEach((value, idx) => {
                        const startDate = value['start_date'] = getDate(value['start_date']);
                        value['end_date'] = getDate(value['end_date']);
                        const prevDate = idx ? items[idx - 1]['start_date'] : undefined;
                        if (!prevDate || prevDate.getMonth() !== startDate.getMonth()
                            || prevDate.getFullYear() !== startDate.getFullYear()) {
                            value['show_timeline_date'] = true;
                        }
                    });

                    for (let idx = 0; idx < items.length; idx++) {
                        const value = items[idx];
                        const startDate = value['start_date'];
                        const endDate = value['end_date'];
                        if (endDate && currentDate >= startDate && currentDate <= endDate) {
                            this.swiperActiveSlideIndex = idx;
                        } else if (startDate >= currentDate) {
                            this.swiperActiveSlideIndex = idx;
                        } else if (idx === items.length - 1) {
                            this.swiperActiveSlideIndex = idx;
                        }

                        if (this.swiperActiveSlideIndex !== -1) {
                            value['is_active'] = true;
                            break;
                        }
                    }

                    this.items = items;
                }).finally(() => {
                    if (this.swiperActiveSlideIndex === -1) {
                        this.swiperActiveSlideIndex = this.items.length - 1;
                    }
                })
        },

        itemsCompareFn(a, b) {
            const cmp = (a, b) => {
                if (a > b) return 1;
                if (a < b) return -1;
                return 0
            }
            return cmp(a['start_date'], b['start_date'])
                || cmp(a['title'], b['title']);
        },

        initSlider() {
            if (this.swiper) return;
            this.swiper = new Swiper(this.$refs.swiper,
                {
                    observer: true,
                    speed: 900,
                    observeParents: true,
                    slidesPerView: 1.3,
                    autoHeight: true,
                    grabCursor: true,
                    slideActiveClass: '_active-slide',
                    navigation: {
                        prevEl: this.$refs.prevSlideButton,
                        nextEl: this.$refs.nextSlideButton,
                        disabledClass: '_disabled',
                    },
                    pagination: {
                        el: this.$refs.progressbar,
                        type: 'progressbar',
                    },

                    breakpoints: {
                        320: {
                            spaceBetween: 16,
                            slidesPerView: 1.3,
                        },
                        480: {
                            spaceBetween: 24,
                            slidesPerView: 2.2,
                        },
                        780: {
                            spaceBetween: 32,
                            slidesPerView: 3.2,
                        },
                        992: {
                            spaceBetween: 40,
                            slidesPerView: 3,
                        },
                        1920: {
                            spaceBetween: 48,
                            slidesPerView: 4,
                        }
                    }
                });
        }
    }
}
</script>

<style scoped>

</style>