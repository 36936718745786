<template>
  <div class="timeline__date">
    <h4 v-if="showTimelineDate">{{ timelineDate }}</h4>
  </div>

  <div class="game-card game-card_overlay">
    <a class="game-card__body" :href="link" :class="{'disabled': !link}" target="_blank">
      <div class="game-card__image _ibg">
        <div class="game-card__overlay" v-html="description"></div>
        <div class="game-card__labels">
          <div class="label label__date" :class="{'label__date__range': endDate}">
            <div>
              <h4>{{ getDay(startDate) }}</h4>
              <span>{{ getMonth(startDate) }}</span></div>
            <div v-if="endDate"> -</div>
            <div v-if="endDate">
              <h4>{{ getDay(endDate) }}</h4>
              <span>{{ getMonth(endDate) }}</span>
            </div>
          </div>
        </div>
        <div class="image-container" ref="imageContainer">
          <img :src="imageUrl" alt="kendoo">
        </div>
      </div>
      <div class="game-card__description" :class="{'_icon-arrow-3': link}">
        <p v-html="title"></p>
      </div>
    </a>
  </div>
</template>

<script>
import {loadAndAppendImageAnimated} from "@/lib/common_utils";

export default {
    name: "ProductRoadmapItemCard",
    props: {
        title: String,
        description: String,
        startDate: Date,
        endDate: Date,
        link: String,
        imageUrl: String,
        showBadges: Boolean,
        locale: String,
        showTimelineDate: Boolean,
    },

    data() {
        return {
            preloadedImg: new Image(),
        }
    },

    watch: {
        imageUrl(oldV, newV) {
            this.updateImg();
        }
    },

    computed: {
        timelineDate() {
            return this.getLocalizedDate(
                this.startDate, {'month': 'long', 'year': 'numeric'});
        }
    },

    methods: {
        updateImg() {
            let imgContainerEl = this.$refs["image-container"];
            imgContainerEl.innerHTML = '';
            if (this.preload) {
                loadAndAppendImageAnimated(imgContainerEl, this.preloadedImg, this.imageUrl);
            } else {
                this.preloadedImg.src = this.imageUrl;
                imgContainerEl.appendChild(this.preloadedImg);
            }
        },

        getLocalizedDate(date, options) {
            if (date instanceof Date) {
                return date.toLocaleDateString('en', options);
            }
        },

        getDay(date) {
            return this.getLocalizedDate(date, {'day': 'numeric'});
        },

        getMonth(date) {
            return this.getLocalizedDate(date, {'month': 'short'});
        }
    }
}
</script>

<style scoped>

</style>