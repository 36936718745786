<template>
  <main objname="games" class="games-grid">
    <SummaryGrid
        v-if="gridOptions !== null"
        :options="gridOptions"
        :user="user"/>
  </main>
</template>


<script>
import {isMobile} from "@/lib/browser";
import {appFetch} from '@/lib/request_utils';
import SummaryGrid from './grid';

const useFixedColumns = !isMobile.any();

let fullSchema = [
    {
        "name": "uid",
        "type": "text",
        "title": "pk",
        "width": "0"
    }, {
        "name": "game_id",
        "type": "text",
        "title": "Game ID",
        "width": "200",
        "cssClass": "summary-left-align-column"
    }, {
        "name": "platform_game_id",
        "type": "text",
        "title": "Platform Game ID",
        "width": "100"
    }, {
        "name": "title_en",
        "type": "text",
        "title": "English Title",
        "width": "150",
        "cssClass": "summary-left-align-column",
        "fixed": useFixedColumns
    }, {
        "nested_header": "SPECIFICATIONS",
        "items": [
            {
                "name": "title_zh",
                "type": "text",
                "title": "Simplified Chinese Title",
                "width": "150",
                "cssClass": "summary-left-align-column"
            }, {
                "name": "demo_link",
                "type": "link",
                "title": "Demo",
                "width": "70",
                "target": "_blank"
            }, {
                "name": "guide_link",
                "type": "link",
                "title": "Guide (pdf)",
                "width": "70",
                "download": "true",
                "target": "_blank"
            }, {
                "name": "promo_link",
                "type": "link",
                "title": "Promo",
                "width": "70",
                "target": "_blank"
            }, {
                "name": "release_date",
                "type": "calendar",
                "title": "Release Date",
                "width": "90",
                "options": {"format": "YYYY-MM-DD"}
            }, {
                "name": "clean_up_date",
                "type": "calendar",
                "title": "Clean Up Date",
                "width": "90",
                "options": {"format": "YYYY-MM-DD"}
            },
        ]
    }, {
        "nested_header": "MATH",
        "items": [{
            "name": "default_rtp",
            "type": "text",
            "title": "RTP, %",
            "width": "60"
        }, {
            "name": "default_volatility",
            "type": "volatility",
            "title": "Volatility",
            "width": "60",
        }, {
            "name": "default_rtp_deviation",
            "type": "text",
            "title": "Deviation of RTP, %",
            "width": "100"
        }, {
            "name": "default_tb",
            "type": "text",
            "title": "Max Exposure (TB)",
            "width": "70"
        }, {
            "name": "default_main_game_hit_freq",
            "type": "text",
            "title": "Main Game Hit Freq, %",
            "width": "100"
        }, {
            "name": "default_free_game_hit_freq",
            "type": "text",
            "title": "Free Game Hit Freq, %",
            "width": "100"
        }, {
            "name": "default_main_game_rtp",
            "type": "text",
            "title": "Main Game RTP, %",
            "width": "100"
        }, {
            "name": "default_bonus_game_rtp",
            "type": "text",
            "title": "Bonus Game RTP, %",
            "width": "100"
        }, {
            "name": "default_free_game_rtp",
            "type": "text",
            "title": "Free Game RTP, %",
            "width": "100"
        }]
    }, {
        "nested_header": "MATH Lowest",
        "items": [{
            "name": "lowest_rtp",
            "type": "text",
            "title": "RTP, %",
            "width": "60"
        }, {
            "name": "lowest_volatility",
            "type": "volatility",
            "title": "Volatility",
            "width": "60"
        }, {
            "name": "lowest_rtp_deviation",
            "type": "text",
            "title": "Deviation of RTP, %",
            "width": "100"
        }, {
            "name": "lowest_tb",
            "type": "text",
            "title": "Max Exposure (TB)",
            "width": "70"
        }, {
            "name": "lowest_main_game_hit_freq",
            "type": "text",
            "title": "Main Game Hit Freq, %",
            "width": "100"
        }, {
            "name": "lowest_free_game_hit_freq",
            "type": "text",
            "title": "Free Game Hit Freq, %",
            "width": "100"
        }, {
            "name": "lowest_main_game_rtp",
            "type": "text",
            "title": "Main Game RTP, %",
            "width": "100"
        }, {
            "name": "lowest_bonus_game_rtp",
            "type": "text",
            "title": "Bonus Game RTP, %",
            "width": "100"
        }, {
            "name": "lowest_free_game_rtp",
            "type": "text",
            "title": "Free Game RTP, %",
            "width": "100"
        }]
    }, {
        "nested_header": "MATH Low",
        "items": [{
            "name": "low_rtp",
            "type": "text",
            "title": "RTP, %",
            "width": "60"
        }, {
            "name": "low_volatility",
            "type": "volatility",
            "title": "Volatility",
            "width": "60"
        }, {
            "name": "low_rtp_deviation",
            "type": "text",
            "title": "Deviation of RTP, %",
            "width": "100"
        }, {
            "name": "low_tb",
            "type": "text",
            "title": "Max Exposure (TB)",
            "width": "70"
        }, {
            "name": "low_main_game_hit_freq",
            "type": "text",
            "title": "Main Game Hit Freq, %",
            "width": "100"
        }, {
            "name": "low_free_game_hit_freq",
            "type": "text",
            "title": "Free Game Hit Freq, %",
            "width": "100"
        }, {
            "name": "low_main_game_rtp",
            "type": "text",
            "title": "Main Game RTP, %",
            "width": "100"
        }, {
            "name": "low_bonus_game_rtp",
            "type": "text",
            "title": "Bonus Game RTP, %",
            "width": "100"
        }, {
            "name": "low_free_game_rtp",
            "type": "text",
            "title": "Free Game RTP, %",
            "width": "100"
        }]
    }, {
        "nested_header": "MATH High",
        "items": [{
            "name": "high_rtp",
            "type": "text",
            "title": "RTP, %",
            "width": "60"
        }, {
            "name": "high_volatility",
            "type": "volatility",
            "title": "Volatility",
            "width": "60"
        }, {
            "name": "high_rtp_deviation",
            "type": "text",
            "title": "Deviation of RTP, %",
            "width": "100"
        }, {
            "name": "high_tb",
            "type": "text",
            "title": "Max Exposure (TB)",
            "width": "70"
        }, {
            "name": "high_main_game_hit_freq",
            "type": "text",
            "title": "Main Game Hit Freq, %",
            "width": "100"
        }, {
            "name": "high_free_game_hit_freq",
            "type": "text",
            "title": "Free Game Hit Freq, %",
            "width": "100"
        }, {
            "name": "high_main_game_rtp",
            "type": "text",
            "title": "Main Game RTP, %",
            "width": "100"
        }, {
            "name": "high_bonus_game_rtp",
            "type": "text",
            "title": "Bonus Game RTP, %",
            "width": "100"
        }, {
            "name": "high_free_game_rtp",
            "type": "text",
            "title": "Free Game RTP, %",
            "width": "100"
        }]
    }, {
        "nested_header": "BET SETTINGS",
        "items": [{
            "name": "min_bet",
            "type": "currency",
            "title": "Min Bet",
            "width": "70"
        }, {
            "name": "default_bet",
            "type": "currency",
            "title": "Default Bet",
            "width": "70"
        }, {
            "name": "max_bet",
            "type": "currency",
            "title": "Max Bet",
            "width": "70"
        }, {
            "name": "bet_factor",
            "type": "text",
            "title": "Bet Factor",
            "width": "70"
        }]
    },
    {
        "nested_header": "ADDITIONAL INFORMATION",
        "items": [{
            "name": "has_mobile",
            "type": "bool",
            "title": "Mobile",
            "width": "70"
        }, {
            "name": "has_replay",
            "type": "bool",
            "title": "Replay",
            "width": "70"
        }, {
            "name": "has_spectator",
            "type": "bool",
            "title": "Spectator",
            "width": "70"
        }, {
            "name": "has_history",
            "type": "bool",
            "title": "History",
            "width": "70"
        }, {
            "name": "has_quick_spin",
            "type": "bool",
            "title": "Quick Spin button available",
            "width": "70"
        }]
    }, {
        "nested_header": "PROMO TOOLS SUPPORT",
        "items": [{
            "name": "has_feature_fixed_fb",
            "type": "bool",
            "title": "Fixed FB",
            "width": "70"
        }, {
            "name": "has_feature_flexible_fb",
            "type": "bool",
            "title": "Flexible FB",
            "width": "70"
        }, {
            "name": "has_feature_fs",
            "type": "bool",
            "title": "Feature Free Spins",
            "width": "70"
        }, {
            "name": "feature_fs_avg",
            "type": "text",
            "title": "Feature FS, Win Avg (TB)",
            "width": "70"
        }, {
            "name": "feature_fs_90",
            "type": "text",
            "title": "Feature FS, Win 90% (TB)",
            "width": "70"
        }]
    },
    {
        "nested_header": "GAME DETAILS",
        "items": [
            {
                "name": "game_type",
                "type": "text",
                "title": "Game Type",
                "width": "100"
            }, {
                "name": "game_category",
                "type": "text",
                "title": "Game Category",
                "width": "100"
            }, {
                "name": "screen_resolution",
                "type": "text",
                "title": "Screen Resolution",
                "width": "120"
            }, {
                "name": "lines",
                "type": "text",
                "title": "Lines",
                "width": "70"
            }, {
                "name": "board",
                "type": "text",
                "title": "Board",
                "width": "70"
            }, {
                "name": "free_spins_mode",
                "type": "bool",
                "title": "Free Spins Mode",
                "width": "70"
            }, {
                "name": "bonus_game_mode",
                "type": "bool",
                "title": "Bonus Game Mode",
                "width": "70"
            }, {
                "name": "has_buy_feature",
                "type": "bool",
                "title": "Buy Bonus",
                "width": "70"
            }, {
                "name": "jackpot_type",
                "type": "text",
                "title": "Jackpot Type",
                "width": "70"
            }, {
                "name": "specification",
                "type": "text",
                "title": "Specification",
                "width": "500",
                "cssClass": "summary-left-align-column"
            }
        ]
    }
];


export default {
    name: 'SummaryTabGames',
    components: {
        SummaryGrid
    },
    props: {
        user: {
            type: Object,
            default: {}
        }
    },
    data() {
        return {
            tab: 'games',
            gridOptions: null
        }
    },
    methods: {
        loadGames() {
            appFetch(`/summary/games/`, {
                method: 'GET',
                credentials: 'include',
                params: {}
            }).then(
                res => res.json()
            ).then(res => {
                let schema = this.prepareSchema(res.data);
                this.gridOptions = {
                    ...{
                        title: 'KENDOO',
                        csvFileName: 'games',
                        pkColumn: 'uid',
                        fixedColumnsNumber: useFixedColumns? 3 : 0,
                        activeTab: 'games',
                        gridID: 'games'
                    }, ...schema
                };
            }).catch(res => {
                console.log('------------ TODO GET GAME HANDLE ERROR!', res)
            });
        },

        prepareSchema(res) {
            let allowedSchema = [],
                nestedColumns = [];

            fullSchema.forEach(c => {
                if (c.items) {
                    let allowedNestedSchema = [];
                    c.items.forEach(nc => {
                        if (res.columns.indexOf(nc.name) > -1) {
                            allowedNestedSchema.push({
                                ...{
                                    nested_header: c.nested_header
                                }, ...nc
                            })
                        }
                    })
                    if (allowedNestedSchema.length > 0) {
                        allowedSchema.push.apply(
                            allowedSchema, allowedNestedSchema);
                        nestedColumns.push({
                            colspan: allowedNestedSchema.length,
                            title: c.nested_header || "&nbsp;",
                            width: c['width']
                        });
                    }

                } else {
                    if (res.columns.indexOf(c.name) > -1) {
                        allowedSchema.push({...{}, ...c})
                        nestedColumns.push({
                            colspan: 1,
                            title: "&nbsp;",
                            width: c['width']
                        });
                    }
                }
            });

            return {
                columns: allowedSchema,
                nestedHeaders: nestedColumns,
                data: res.rows
            }
        }
    },
    mounted() {
        this.loadGames();
    },
    computed: {},
}
</script>
