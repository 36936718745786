<template>
  <footer class="footer">
    <div class="footer__angled_backgrounud"></div>
    <div class="footer__content _container">
      <div class="footer__top">
        <div class="footer__info">
          <ul class="info-list">
            <li><p>Kendoo Play Ltd (reg. number 021753V ) is a company incorporated in the Isle of Man at the registered address, Second Floor, 18-20 North Quay, Douglas, Isle of Man, IM1 4LE.</p></li>
            <li><p>Kendoo Play Ltd strictly prohibits participation by persons under the age of 18 years old.</p></li>
          </ul>
        </div>

        <div class="footer__navigation">
          <div class="footer-menu">
            <ul class="footer-menu__list">
              <li v-for="menuItem of menuList.slice(
                  0, Math.round(menuList.length/2))"
              >
                <span class="split-link">
                  <a class="normal" :href="menuItem.path"
                     :target="menuItem.target"
                  >
                    {{ menuItem.title }}
                  </a>
                  <a class="normal-hovered _hover" :href="menuItem.path"
                     :target="menuItem.target"
                  >
                    {{ menuItem.title }}
                  </a>
                </span>
              </li>
            </ul>
            <ul class="footer-menu__list">
              <li v-for="menuItem of menuList.slice(
                  Math.round(menuList.length/2), menuList.length)"
              >
                <span class="split-link">
                  <a class="normal" :href="menuItem.path"
                     :target="menuItem.target"
                  >
                    {{ menuItem.title }}
                  </a>
                  <a class="normal-hovered _hover" :href="menuItem.path"
                     :target="menuItem.target"
                  >
                    {{ menuItem.title }}
                  </a>
                </span>
              </li>
            </ul>
          </div>

          <div class="footer-links">
            <p data-da=".footer-links,992,0">Networks</p>
            <div class="footer-links__list">
              <SocialLinks />
            </div>
          </div>

          <div class="footer-links">
            <p data-da=".footer-links,992,0">Links</p>
            <div class="footer-links__list">
              <ul>
                <li>
                  <a href="/static/media/Mediakit.pdf" target="_blank" rel="nofollow noopener noreferrer">
                    Mediakit
                  </a>
                </li>
                <li>
                  <a href="/static/media/Guidelines_for_Using_Kendoo_Art.pdf" target="_blank" rel="nofollow noopener noreferrer">
                    Guidelines for Using Kendoo Art
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <div class="footer-text">
            <div>
              <p>ABOUT KENDOO</p>
              <p>Kendoo provides online casino platforms and game aggregators with an exceptional portfolio of slot games. Based on the Isle of Man, the company combines creativity, technical precision, and regulatory compliance to deliver unforgettable gaming experiences.</p>
            </div>
            <div>
              <p>ALL RIGHTS RESERVED</p>
              <p>Kendoo logos and graphic materials are the company's
                intellectual property and may not be copied, reproduced,
                distributed or displayed without the written consent
                of Kendoo Play LTD</p>
            </div>
          </div>

        </div>
      </div>

      <div class="footer__bottom">
        <div>
            <p>18+ RESPONSIBLE GAMING</p>
            <p><a href="https://www.gambleaware.org/" target="_blank">gambleaware.org</a></p>
          <!--
          <p>
            For business inquiries:
            <a v-bind:href="`mailto:${salesEmail}`">{{ salesEmail }}</a>
          </p>
          -->
        </div>
        <div>
          <p><a href="/static/media/privacy_policy.pdf" target="_blank">Privacy Policy</a></p>
          <p>© {{ year }} KENDOO. All Rights Reserved</p>
        </div>
      </div>
    </div>
  </footer>
</template>


<script>
import SocialLinks from './social_links'

export default {
    name: "Footer",
    props: {
        menuList: Array
    },

    data() {
        return {
            salesEmail: process.env.VUE_APP_SALES_EMAIL,
            year: new Date().getFullYear()
        }
    },

    components: {
        SocialLinks
    }
}
</script>
