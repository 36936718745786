<template>
  <main>
    <div
        class="background--canvas"
        :class="{'background-user--canvas': isClientAreaMode}"></div>

    <div data-barba="wrapper">
      <div class="wrapper" data-barba="container">

        <!-- MENU =============================== -->
        <Menu
            :user="user"
            :menuList="menuList.filter(item => !item.hideForMenu)"
            :isOpened="isMenuOpened"
            @on-click="onMenuItemClick"
        />
        <!-- MENU =============================== END -->

        <!-- HEAD =============================== -->
        <Header
            :user="user"
            :menuList="menuList.filter(item => !item.hideForHeader)"
            :isMenuOpened="isMenuOpened"
            @burgerBtnClick="onBurgerBtnClick"
        />
        <!-- HEAD =============================== END -->

        <!-- ROUTER ============================= -->
        <router-view :user="user" :is-client-area="isClientAreaMode"/>
        <!-- ROUTER ============================= END -->

        <!-- FOOTER ============================= -->
        <Footer
            :menuList="menuList.filter(item => !item.hideForFooter)"
        />
        <!-- FOOTER ============================= END -->

      </div>
    </div>
  </main>
</template>

<script>
import EventBus from '@/lib/event_bus';
import { settings } from '@/lib/settings';

import Menu from './menu';
import Header from './header';
import Footer from './footer';

const careerMenuItem =  {
    title: 'Career',
    path: 'https://apply.workable.com/3-oaks-gaming/?lng=en',
    hideForHeader: true,
    target: '_blank',
}

const menu = [
    // {
    //     title: 'About',
    //     path: '/about'
    // },
    // {
    //     title: 'Games',
    //     path: '/games'
    // },
    // {
    //     title: 'Roadmap',
    //     path: '/roadmap',
    // },
    // {
    //     title: 'Partners',
    //     path: '/partners',
    //     hideForHeader: true,
    // },
    // {
    //     title: 'News',
    //     path: '/news',
    //     hideForHeader: true,
    // },
    // careerMenuItem,
    {
        title: 'News',
        path: '/news'
    },
    // {
    //     title: 'Contacts',
    //     path: '/contacts',
    //     hideForHeader: true,
    // }
];

const menuAuth = [
    // {
    //     title: 'About',
    //     path: '/about'
    // },
    // {
    //     title: 'Games',
    //     path: '/games'
    // },
    // {
    //     title: 'Roadmap',
    //     path: '/roadmap',
    // },
    // {
    //     title: 'Partners',
    //     path: '/partners',
    //     hideForHeader: true,
    // },
    {
        title: 'News',
        path: '/news',
        hideForHeader: true,
    },
    {
        title: 'News',
        path: '/news'
    },
    // {
    //     title: 'Contacts',
    //     path: '/contacts',
    //     hideForHeader: true,
    // },
    {
        title: 'Client Area',
        path: '/client-area',
    },
];

const menuClientArea = [
    {
        title: 'Product Roadmap',
        path: '/client-area/product-roadmap'
    },
    {
        title: 'Campaigns',
        path: '/client-area/campaigns'
    },
    {
        title: 'Games',
        path: '/client-area/games'
    },
    // {
    //     title: 'Promo Tools',
    //     path: '/client-area/promo-tools'
    // },
    {
        title: 'Documents',
        path: '/client-area/documents'
    },
    {
        title: 'Summary',
        path: '/client-area/summary',
        target: '_blank'
    },
];

const PUBLIC_MODE = 'public';
const AUTH_MODE = 'auth';
const CLIENT_AREA_MODE = 'client_area';

const modeMenuListMapping = {
    [PUBLIC_MODE]: menu,
    [AUTH_MODE]: menuAuth,
    [CLIENT_AREA_MODE]: menuClientArea,
}

import {useHead} from "@vueuse/head";
import {appFetchJSON} from "@/lib/request_utils";

export default {
    name: 'EmbeddedPage',
    props: {
        user: Object
    },

    components: {
        Menu,
        Header,
        Footer
    },

    data() {
        return {
            isMenuOpened: false,
            customPages: [],
            refreshMenuKey: 0,
        }
    },

    setup() {
        useHead({
            title: 'KENDOO',
        });
    },

    mounted() {
        careerMenuItem.path = settings.WORKABLE_ACCOUNT_URL;
        this.fetchCustomPages();
    },

    methods: {

        toggleBurgerMenu() {
            let page = this.$el.querySelector('.page');
            this.isMenuOpened = !this.isMenuOpened;
            if (this.isMenuOpened) {
                EventBus.emit(EventBus.EVENTS.BODY_LOCK_REQUEST);
                page && page.classList.add('_blur');
            } else {
                EventBus.emit(EventBus.EVENTS.BODY_UNLOCK_REQUEST);
                page && page.classList.remove('_blur');
            }
        },

        onBurgerBtnClick() {
            this.toggleBurgerMenu();
        },

        fetchCustomPages() {
            if (!this.user.is_authorized) return;
            return appFetchJSON('/custom-pages')
                .then(res => {
                    this.customPages = res.data.items;
                    this.customPages.forEach(item => {
                        const isPublished = item['is_published'];
                        if (isPublished || this.user.is_staff) {
                            let title = item.title.replace(/(<([^>]+)>)/ig, '')
                            if (!isPublished) {
                                title += ' (not published)'
                            }
                            const menuItem = {
                                title: title,
                                path: `/client-area/custom-pages/${item.slug}`,
                                hideForHeader: true,
                            }
                            menuClientArea.push(menuItem);
                        }
                    });
                    this.refreshMenuKey++;
                });
        },

        onMenuItemClick(item) {
            if (item.target === '_blank') {
                this.toggleBurgerMenu();
            }
        }
    },

    computed: {
        mode() {
            if (this.user.is_authorized) {
                if (this.$route.matched.some((route) => route.name === 'client-area')) {
                    return CLIENT_AREA_MODE;
                }
                return AUTH_MODE;
            }
            return PUBLIC_MODE;
        },

        menuList() {
            this.refreshMenuKey;
            return modeMenuListMapping[this.mode];
        },

        isClientAreaMode() {
            return this.mode === CLIENT_AREA_MODE;
        }
    }
}
</script>



