<template>
  <section class="article-page__content offset-section">
    <h2 v-html="article.title"></h2>
    <div class="article-page__card">
      <div class="column">
        <div class="_img-box _ibg">
          <img :src="article.image" alt="kendoo">
        </div>
      </div>
      <div class="column">
        <h4 v-html="article.summary"></h4>
        <div class="info">
          <div class="info__item">
            <div class="name">Publication Date</div>
            <div class="description"><DateWidget :iso-date-string="article.c_at"/></div>
          </div>
          <div class="info__item">
            <div class="name">Сategory</div>
            <div class="description">{{ article.category_title }}</div>
          </div>
<!--          <div class="info__item info__item_big">-->
<!--            <div class="name">Share</div>-->
<!--            <div class="description">-->
<!--              <ul class="social-list">-->
<!--                <li>-->
<!--                  <a href="#">Facebook</a>-->
<!--                </li>-->
<!--                <li>-->
<!--                  <a href="#">Twitter</a>-->
<!--                </li>-->
<!--                <li>-->
<!--                  <a href="#">Email</a>-->
<!--                </li>-->
<!--              </ul>-->
<!--            </div>-->
<!--          </div>-->
        </div>
      </div>
    </div>
    <div class="_text-box" v-html="article.body"></div>
  </section>
</template>

<script>

import DateWidget from "@/components/date_widget";

export default {
    name: "NewsArticleBlock",
    components: {
        DateWidget,
    },
    props: {
        article: Object,
    }
}
</script>

<style scoped>

</style>